require('chart.js')
require('./Chart.PieceLabel.min.js')
const inView = require("in-view");

export class PieChart {
    constructor() {
        this.setupDone = false
        this.CHART_TYPE = 'doughnut';
        this.defaults = {
            chartBgColour: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 0.6)"]
        }

        this.activeCharts = [];
    }

    setup() {
        this.$chart = $('.js-chart');

        this.$chart.each((i, el) => {

            //Chart.defaults.global.defaultFontColor = "'FFMark', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif'";

            const canvas = $('canvas', el);
            const data = $(el).data();
            const canInit = canvas != null
                && data.chartType && data.chartType == this.CHART_TYPE
                && data.chartData && data.chartData.length > 0;

            if (canInit) {
                {
                    const chart = this.initialise(canvas, data);

                    // Create unique ID for inView selector.
                    const uniqueClassName = `chart-doughnut-${this.activeCharts.length}`;
                    $(el).addClass(uniqueClassName);

                    // onEnter event render chart.
                    inView(`.${uniqueClassName}`).on('enter', function(){
                        chart.render();
                    });
                }
            }
        })

        this.setupDone = true
    }

    initialise(canvas, data) {
        const ctx = canvas.get(0).getContext('2d');
        let chartData = {};
        $.extend(chartData, this.defaults, data);

        Chart.defaults.global.defaultFontFamily = "FFMark";

        var c = new Chart(ctx, {
            "type": this.CHART_TYPE,
            "data": {
                "labels": chartData.chartLabels,
                "datasets": [
                    {
                        "data": chartData.chartData,
                        "backgroundColor": chartData.chartBgColour
                    }
                ]
            },
            options: {
                //defaultFontFamily: "'FFMark', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif'",
                defaultFontSize: 12,
                legend: {
                    display: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0,
                    }
                },
                tooltips: {
                    enabled: false
                },
                pieceLabel: {
                    render: 'label',
                    fontColor: '#FFF',
                    defaultFontSize: 12,
                    //fontFamily: "'FFMark', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif'",
                    position: 'outside',
                    outsidePadding: 40
                }
            }
        });

        // Pauses animation. Use c.render() to restart.
        c.stop();
        // Store for removal or manipulation later.
        // Also used to create unique class name.
        this.activeCharts.push(c);

        return c;
    }

    destroy() {
        if (this.setupDone) {
            //destroy / cleanup

            this.setupDone = false;
        }
    }

}
