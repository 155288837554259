const Barba = require("barba.js");

export class PageLoading {
    constructor() {
        this.linkClicked = false;
        this.backClicked = false;
        this.backCloseButtonClicked = false;
    }

    setBackClosedClicked(){
        this.backCloseButtonClicked = true;
    }
    setBackClosedNotClicked(){
        this.backCloseButtonClicked = false;
    }

    gotoPreviousURL(e){
        if(this.backCloseButtonClicked){
            //going back to a page after already closing an 'overlay', dont use backstate, go back to list
            if($('.pageTrack').data('listpage') != "" && $('.pageTrack').data('listpage') != undefined){
                //console.log("gtpu-bc-1");
                window.location.href = $('.pageTrack').data('listpage');
            }else{
                //console.log("gtpu-bc-2");
                window.history.back();
            }
            this.setBackClosedNotClicked();
        }else{
            this.setBackClosedClicked();
            var previousHistoryState = Barba.HistoryManager.prevStatus();
            if(previousHistoryState){
                //console.log("gtpu-1");
                if(e){
                    //console.log("gtpu-1e");
                    e.preventDefault();
                    e.stopPropagation();
                }
                //'close' entry by going back with Barba
                Barba.Pjax.goTo(previousHistoryState.url);
            }else{
                //Barba not used
                //'close' entry by history state
                //console.log("gtpu-2");
                if(document.referrer.indexOf("bro.dk") > -1){
                    //console.log("gtpu-2-1");

                    if(window.history.length == 1){
                        //coming from elsewhere on the site in a new tab
                        //do nothing, allow to use defualt link
                    }else{
                        //coming from elsewhere on the site, go back in history
                        if(e){
                            //console.log("gtpu-2-1e");
                            e.preventDefault();
                            e.stopPropagation();
                        }

                        window.history.back();
                    }

                }else{
                    //console.log("gtpu-2-2");
                    //coming from another site, go to list page
                    if($('.pageTrack').data('listpage') != "" && $('.pageTrack').data('listpage') != undefined){
                        //console.log("gtpu-2-2-1");
                        window.location.href = $('.pageTrack').data('listpage');
                    }else{
                        //console.log("gtpu-2-2-2");
                        window.history.back();
                    }
                }
            }

        }
    }

    setupBarba() {
        Barba.Pjax.Dom.wrapperId = "loading-container";
        Barba.Pjax.Dom.containerClass = "loaded-content";
        Barba.Utils.xhrTimeout = 7000;
        Barba.Pjax.start();
        Barba.Prefetch.init();

        Barba.Dispatcher.on('linkClicked', function() {
            $('html').addClass('pjax-loading');
            $('html').removeClass('menuHovering');
            $('.menu-el').removeClass('hover');

            //global.podcastPlayer.pauseAndClose();
            global.blogNavigation.closeBlogNav();
            global.documentReady.closeMobileMenu();

            this.linkClicked = true;
        });
        Barba.Dispatcher.on('initStateChange', function() {
            if (!this.linkClicked) {
                this.backClicked = true;
            }

            global.blogNavigation.closeBlogNav();
        });
        Barba.Dispatcher.on('newPageReady', function() {
            $('body').removeClass('force-reveal');
            $('html').removeClass('no-scroll');
            global.blogNavigation.closeBlogNav();
            global.initPage.removeFooterTracking();

        });
        Barba.Dispatcher.on('transitionCompleted', function() {
            global.utils.scrollToTop(1);
            global.pageReady.setup();
            if (this.backClicked) {
                $('body').addClass('force-reveal');
            }
            global.initPage.setup();
            $('html').removeClass('pjax-loading');
            this.linkClicked = false;
            this.backClicked = false;
        });

        var FadeTransition = Barba.BaseTransition.extend({
            start: function() {
                Promise
                    .all([this.newContainerLoading, this.fadeOut()])
                    .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
                return $(this.oldContainer).animate({ opacity: 0 }, 100).promise();
            },

            fadeIn: function() {
                /**
                 * this.newContainer is the HTMLElement of the new Container
                 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
                 * Please note, newContainer is available just after newContainerLoading is resolved!
                 */

                var _this = this;
                var $el = $(this.newContainer);

                $(this.oldContainer).hide();

                $el.css({
                    visibility: 'visible',
                    opacity: 0
                });
                _this.done();

                $el.animate({ opacity: 1 }, 400, function() {});
            }
        });

        Barba.Pjax.getTransition = function() {
            return FadeTransition;
        };
    }

}
