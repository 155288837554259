export class Resizing {
    constructor() {
        this.resizeTimer = null;
    }

    setup() {
        const rHandler = global.utils.throttled(100, this.resizeHandler);
        if(window.attachEvent) {
            window.attachEvent('onresize', rHandler);
        }else if(window.addEventListener) {
            window.addEventListener('resize', rHandler, true);
        }else {
            //The browser does not support Javascript event binding
            window.onresize = rHandler;
        }

        this.resizeHandler();
    }

    onResize(c, t) {
        onresize = function() {
            clearTimeout(t);
            t = setTimeout(c, 100);
        };
        return c;
    }

    resizeHandler() {
        global.utils.updateSizeRefs();
        //console.log(global.viewportWidth + " x " + global.viewportHeight);

        global.utils.measureScrollBarWidth();

        if(global.viewportHeight <= 450){
            $('html').addClass('vp-short');
        }else{
            $('html').removeClass('vp-short');
        }
        if($('html').hasClass('no-touch')){
            if(global.viewportHeight <= 620){
                $('html').addClass('vp-short');
            }else{
                $('html').removeClass('vp-short');
            }
        }

        //iOS scrolling fixes
        $('.fp-enabled .section:not(.fp-auto-height)').css("min-height",global.viewportHeight+"px");
        if($('html').hasClass('fp-enabled')){
            $('.introSlider .centreLock').css("height",global.viewportHeight+"px");
            $('.introSlider .centreLock-margin').css("height",global.viewportHeight+"px");

            $('.section.caseStudyHeader-section').css("height",global.viewportHeight+"px");

        }else{
            $('.introSlider .centreLock').removeAttr("style");
            $('.introSlider .centreLock-margin').removeAttr("style");

            $('.section.caseStudyHeader-section').removeAttr("style");
        }

        var sliderH = global.utils.getMaxHeight($('.introSlider-contentSets-set-header')) + 20;
        $('.introSlider').css('min-height',sliderH+'px');

        var fBLW = Math.floor($('.featuredBlock-linkBlock-link').width());
        $('.featuredBlock-linkBlock-link').css('min-height', fBLW*(3/4)+'px');

        //force scale all linkblocks in a row to the same min height
        if($('.featuredBlock').length > 0){
            $('.featuredBlock').each(function(){
                var minHeight = 1;
                $(this).find('.linkBlock-content').each(function(){
                   if ($(this).height() > minHeight) { minHeight = $(this).height(); }
                });
                var ratioCheck = ($(this).find('.linkBlock-content').width() / 4)*3;
                if(minHeight < ratioCheck){
                    minHeight = ratioCheck;
                }
                //minHeight = Math.max(minHeight,global.utils.viewportWidth*0.22);
                $(this).find('.linkBlock-content').css("min-height",minHeight);
            });
        }

        if($('.contentBlock-imageQuote').length > 0){
            $('.contentBlock-imageQuote').each(function(){
                $(this).css('min-height',$(this).find('.quote-holder').outerHeight()+'px');
            });
        }

        if($('.contentBlock-image .fullbleed').length > 0){
            $('.contentBlock-image .fullbleed').each(function(){
                if(!$(this).parent().parent().hasClass('contentBlock-section-cb')){
                    $(this).css('height',global.viewportHeight+'px');
                }
            });
        }

        //$('.contentBlock-graph').css('min-height',global.viewportHeight+'px');
        $('.contentBlock-caseStudyHeader .contentBlockContent').css('min-height',global.viewportHeight+'px');
        $('.page-blogs-themed .contentBlock-caseStudyHeader .contentBlockContent').css('min-height',global.viewportHeight*0.75+'px');

        $('.contentBlock-graph-arches .mobileGraph .arches-svg').css('height',global.viewportHeight * 0.9+'px');

        $('.contentBlock-graph-piechart .contentBlockContent').css('min-height',global.viewportHeight+'px');
        $('.contentBlock-section--1-2 .contentBlock-section-col--last .contentBlock-graph-piechart .contentBlockContent').css('min-height',global.viewportHeight / 2+'px');
        $('.contentBlock-section--2-1 .contentBlock-section-col--first .contentBlock-graph-piechart .contentBlockContent').css('min-height',global.viewportHeight / 2+'px');
        $('.contentBlock-section--1-2 .contentBlock-section-col--last .contentBlock-graph-piechart .piecharts').css('min-height',global.viewportHeight / 2+'px');
        $('.contentBlock-section--2-1 .contentBlock-section-col--first .contentBlock-graph-piechart .piecharts').css('min-height',global.viewportHeight / 2+'px');

        $('.contentBlock-section--1-2 .contentBlock-section-col--first .contentBlock-graph-piechart .piecharts').css('min-height',global.viewportHeight+'px');
        $('.contentBlock-section--2-1 .contentBlock-section-col--last .contentBlock-graph-piechart .piecharts').css('min-height',global.viewportHeight+'px');

        /*if(global.viewportWidth <= 914 && global.viewportWidth > 540){
            $('.contentBlock-section-col .contentBlock-graph-piechart .contentBlockContent .piecharts').each(function(){
                //$(this).css('min-height',$(this).parent().height()+"px");
            });
        }else{
            //$('.contentBlock-section-col .contentBlock-graph-piechart .contentBlockContent .piecharts').removeAttr('style');
        }*/
        $('.pieChart-section .piecharts').each(function(){
            var off = Math.max(1,($(this).parent().height() - $(this).height())/2);
            $(this).css('padding-top', off +"px");
        });
        $('.mobileGraph').css('min-height',global.viewportHeight+'px');
        $('.mobileGraph').each(function(){
            var off = Math.max(1,($(this).height() - $(this).find('.graphSlider').height())/3);
            $(this).find('.graphSlider').css('padding-top', off +"px");
        });

        /*if($('.moreFromBlock').length > 0){
            $('.moreFromBlock').each(function(){
                var offset = global.viewportHeight - (80 + $(this).find('.homeSection-header').outerHeight(true) + $(this).find('.featuredBlock').height());
                $(this).find('.featuredBlock').css("margin-top",offset/2+"px");
            });
        }*/

        if($('html').hasClass('fp-enabled')){
            //vertically centre main copy in case study intro
            if($('.contentBlock-caseStudyIntro').length > 0){
                $('.contentBlock-caseStudyIntro').each(function(){
                    var offset = Math.max(2, $(this).outerHeight() - $(this).find('.desktopContent .contentBlock-caseStudyIntro-cols').outerHeight() - $(this).find('.desktopContent .copy-holder').outerHeight());
                    $(this).find('.desktopContent .copy-holder').css('margin-top',offset/2+'px');
                });
            }

            /*if($('.contentBlock-section--1-2').length > 0){
                $('.contentBlock-section--1-2').each(function(){
                    var mH = $(this).find('.contentBlock-section-col--last').height();
                    $(this).find('.contentBlock-section-col--first .contentBlockContent').css('min-height',mH+'px');
                });
            }
            if($('.contentBlock-section--2-1').length > 0){
                $('.contentBlock-section--2-1').each(function(){
                    var mH = $(this).find('.contentBlock-section-col--first').height();
                    $(this).find('.contentBlock-section-col--last .contentBlockContent').css('min-height',mH+'px');
                });
            }
            if($('.contentBlock-section--1-1').length > 0){
                $('.contentBlock-section--1-1').each(function(){
                    var mH = Math.max($(this).find('.contentBlock-section-col--first').height(), $(this).find('.contentBlock-section-col--last').height());
                    $(this).find('.contentBlock-section-col .contentBlockContent').css('min-height',mH+'px');
                });
            }*/

            //vertically centre quote in quote block
            if($('.contentBlock-quote').length > 0){
                $('.contentBlock-quote').each(function(){
                    var offset = Math.max(1, ($(this).outerHeight() - $(this).find('.quote-holder').outerHeight(true))/2);
                    $(this).find('.centreLock').css('margin-top',offset+'px');
                });
            }

            //vertically centre copy in copy block
            if($('.contentBlock-copy').length > 0){
                $('.contentBlock-copy').each(function(){
                    var offset = Math.max(1, ($(this).height() - $(this).find('.contentBlock-copy-copy').outerHeight(true))/2);
                    $(this).find('.contentBlock-copy-copy').css('margin-top',offset+'px');
                });
            }

            //vertically centre header in header block
            if($('.contentBlock-header').length > 0){
                $('.contentBlock-header').each(function(){
                    var offset = Math.max(1, ($(this).outerHeight() - $(this).find('.contentBlockContent').outerHeight())/2);
                    $(this).find('.centreLock').css('margin-top',offset+'px');
                });
            }

            //vertically centre podcast embed in podcast block
            /*if($('.contentBlock-podcast').length > 0){
                $('.contentBlock-podcast').each(function(){
                    var offset = $(this).outerHeight() - $(this).find('.contentBlockContent').outerHeight();
                    $(this).find('.centreLock').css('margin-top',offset/2+'px');
                });
            }*/

            //vertically centre timeline in timeline block
            if($('.contentBlock-timeline').length > 0){
                $('.contentBlock-timeline').each(function(){
                    var offset = Math.max(1, ($(this).outerHeight() - $(this).find('.contentBlockContent').outerHeight())/2);
                    $(this).find('.centreLock').css('margin-top',offset+'px');
                });
            }

            //vertically centre client logos
            if($('.clientLogos').length > 0){
                $('.clientLogos').each(function(){
                    var offset = Math.max(40, (global.viewportHeight - $(this).height())/2);
                    $(this).css('padding-top',offset+'px');
                });
            }
        }

        if(global.viewportWidth <= 540){
            if($('.footerCallout-block').length > 0){
                $('.footerCallout-block').each(function(){
                    $('.footerCallout-block').css('min-height',global.viewportHeight+"px");
                });
            }
            if($('.footer-container').length > 0){
                $('.footer-container').each(function(){
                    $('.footer-container').css('min-height',global.viewportHeight+"px");
                });
            }
            if($('.homeSection').length > 0){
                $('.homeSection').each(function(){
                    $('.homeSection').css('min-height',global.viewportHeight+"px");
                });
            }
            if($('.registration-section .mobileSlides .flickity-viewport').length > 0){
                $('.registration-section .mobileSlides .flickity-viewport').each(function(){
                    //$('.registration-section .mobileSlides .flickity-viewport').css('min-height',global.viewportHeight+"px");
                });
            }
            if($('.caseStudyIntro-holder-mobile .copy-holder').length > 0){
                $('.caseStudyIntro-holder-mobile .copy-holder').each(function(){
                    $(this).css('margin-top',(global.viewportHeight - $(this).height())/2+"px");
                });
            }
            if($('.caseStudyIntro-holder-mobile .copy-holder').length > 0){
                $('.caseStudyIntro-holder-mobile .copy-holder').each(function(){
                    $(this).css('margin-top',(global.viewportHeight - $(this).height())/2+"px");
                });
            }
            if($('.caseStudyIntro-holder-mobile .caseStudyIntroRow').length > 0){
                $('.caseStudyIntro-holder-mobile .caseStudyIntroRow').each(function(){
                    $(this).css('margin-top',(global.viewportHeight - $(this).height())/2+"px");
                });
            }
            if($('.contentBlock-section--1-2 .contentBlock-section-col--first').length > 0){
                $('.contentBlock-section--1-2 .contentBlock-section-col--first').each(function(){
                    $('.contentBlock-section--1-2 .contentBlock-section-col--first').css('height',global.viewportHeight+"px");
                });
            }
            if($('.contentBlock-section--1-1 .contentBlock-section-col').length > 0){
                $('.contentBlock-section--1-1 .contentBlock-section-col').each(function(){
                    $('.contentBlock-section--1-1 .contentBlock-section-col').css('height',global.viewportHeight+"px");
                });
            }
            if($('.contentBlock-section--2-1 .contentBlock-section-col--last').length > 0){
                $('.contentBlock-section--2-1 .contentBlock-section-col--last').each(function(){
                    $('.contentBlock-section--2-1 .contentBlock-section-col--last').css('height',global.viewportHeight+"px");
                });
            }
            /*if($('.moreFromBlock').length > 0){
                $('.moreFromBlock').each(function(){
                    var offset = global.viewportHeight - (80 + $(this).find('.homeSection-header').outerHeight(true) + $(this).find('.featuredBlock').height());
                    $(this).find('.homeSection-content').css("margin-top",offset/2+"px");
                    $(this).find('.featuredBlock').css("margin-top","0px");
                });
            }*/

            $('.blogFeature').css('min-height',global.viewportHeight - 114+"px");

            $('.homeSection-content').each(function(){
                offset = Math.min(-50, -20-(global.viewportHeight - $(this).outerHeight(true)));
                $(this).find('.featuredBlock-cols-mobile .flickity-page-dots').css('bottom', offset + "px");
            });
            $('.blogFeature .blogFeature-content').each(function(){
                offset = Math.min(-50, 150-(global.viewportHeight - $(this).outerHeight(true)));
                $(this).find('.featuredBlock-cols-mobile .flickity-page-dots').css('bottom', offset + "px");
            });
        }else{
            $('.footerCallout-block').removeAttr('style');
            $('.footer-container').removeAttr('style');
            $('.homeSection').removeAttr('style');
            $('.contentBlock-section--1-2 .contentBlock-section-col--first').removeAttr('style');
            $('.contentBlock-section--1-1 .contentBlock-section-col').removeAttr('style');
            $('.contentBlock-section--2-1 .contentBlock-section-col--last').removeAttr('style');

            $('.blogFeature').removeAttr('style');
            $('.featuredBlock-cols-mobile .flickity-page-dots').removeAttr('style');
        }

        if($('.mobileContentSlider').length > 0){
            $('.mobileContentSlider').height(global.viewportHeight);
            $('.mobileContentSlider .flickity-viewport').height(global.viewportHeight);
            $('.mobileContentSlide').height(global.viewportHeight);
            $('.mobileContentSlide .centreLock').height(global.viewportHeight);
        }

        if($('.desktopGraph').length > 0){
            var offset = Math.max(0, (viewportHeight - $('.desktopGraph').height())/2);
            $('.desktopGraph').css("margin-top",offset+"px");
        }

        $('.blogGrid .authorFeature').css('min-height', Math.ceil($('.blogGrid .blogFeature').eq(0).outerHeight(true))+'px');

        global.initPage.resizeCarousels();

    }

}
