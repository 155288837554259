const fitvids = require("fitvids");
const Flickity = require("flickity");
const FlickityFade = require('flickity-fade');
const inView = require("in-view");
const Velocity = require("velocity-animate");
const Vivus = require("vivus");
const lottie = require("lottie-web");

export class InitPage {
    constructor() {
        this.pageLoaded = false;
        this.viewportScrolling = false;

        this.blogNavOpened = false;
        this.delayedDisplayCnt = 0;

        this.animatedSVGCnt = 0;

        this.galCnt = 0;

        this.animationData = null;
        this.introAnim = null;
        this.destroyIntroOnComplete = true;
        this.galleryArrow = 'M50,100c27.6,0,50-22.4,50-50c0-27.6-22.4-50-50-50C22.4,0,0,22.4,0,50S22.4,100,50,100z M57.9,25.1l5,5L43,50l19.9,19.9l-5,5L33.1,50L57.9,25.1z';
        this.flickities = [];
    }

    setup() {
        (async() => {
            try {

                await this.setupFooterTracking();
                await this.setupFitVids();
                await this.setupSectionScrolling();
                await this.setupAnimatedOverlay();
                await this.setupIntroSlider();
                await this.setupAnimatedTitles();
                await this.setupCharts();
                await this.setupVideoOverlay();
                await this.setupBlogElements();
                await this.setupCollapsibleNavs();
                await this.setupGalleries();
                await this.updateNavigation();
                await this.setupDelayedLoads();
                await this.setupChartInteractions();
                await this.setupChartAnimations();
                await this.setupChartGalleries();

                await this.setupGrid();
                await this.setupForms();
                await global.resizing.resizeHandler();

            } catch (error) {
                console.error('(FROM ASYNC/AWAIT) Error cause is:', error);
            }
        })();

        $('body').addClass('page-loaded');
        $('html').removeClass('scrollDown');
        this.pageLoaded = true;

        $('.featuredBlock-linkBlock-link').on('click',function(){
        	global.pageLoading.setBackClosedNotClicked();
        });

        if($('.pageContent-microsite').length > 0){
            if(!$('body').hasClass('page-sites') && !$('body').hasClass('page-blogs-themed')){
                //We're on a microsite and the nav is displayed, refresh to remove the nav
                window.location.reload();
            }
        }
    }

    setupFooterTracking(){
        if($('.footer-container').length > 0){
            inView('.footerCols-links-socialLinks').on('enter', function(){
                $('html').addClass('footerInview');
            }).on('exit', function(){
                $('html').removeClass('footerInview');
            });
        }
    }

    removeFooterTracking(){
        $('html').removeClass('footerInview');
    }

    setupFitVids() {
        fitvids('.video-holder');
    }

    setupSectionScrolling() {
        (async() => {
            await global.viewportScrolling.destroy();
            await global.viewportScrolling.setup();
        })();

        global.resizing.resizeHandler();
        global.viewportScrolling.rebuildScrolling();
        setTimeout(function(){
            global.viewportScrolling.rebuildScrolling();
        },1000);
        setTimeout(function(){
            global.viewportScrolling.rebuildScrolling();
        },2000);

        $('.nextSectionLink').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            global.viewportScrolling.gotoNextSection();

            return false;
        });
        $('.nextSectionLink2').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            global.viewportScrolling.gotoNextSection();
            global.viewportScrolling.gotoNextSection();

            return false;
        });

    }

    setupAnimatedOverlay() {
        return false;
        if($('html').hasClass('animationOverlay-open') && $('#introAnimEl').length > 0){

            let scope = this;
            $.getJSON( "/animation-data.json", function( data ) {
                scope.setupAnimation(data);
            });

        }else{
            $('html').removeClass('animationOverlay-open');
            $('.animationOverlay').remove();
        }
    }

    setupAnimation(animData){
        this.introAnim = lottie.loadAnimation({
            container: document.getElementById("introAnimEl"),
            renderer: 'canvas',
            loop: false,
            autoplay: true,
            animationData: animData
        });
        lottie.setQuality(2);

        if(scope.destroyIntroOnComplete){
            this.introAnim.addEventListener('complete',function(){
                $('.animationOverlay-open').removeClass('animationOverlay-open');
                try{
                    scope.introAnim.destroy();
                }catch(e){
                    console.warn('cannot remove into anim');
                }

                $('.animationOverlay').remove();
            });
        }
    }

    setupIntroSlider() {
        global.introSlider.destroy();
        if($('html').hasClass('animationOverlay-open') && $('#introAnimEl').length > 0){
            global.introSlider.setup(4000);
        }else{
            global.introSlider.setup();
        }
    }

    setupAnimatedTitles() {
        global.animatedTitle.destroy();
        global.animatedTitle.setup();
    }

    setupCharts(){
        global.pieChart.destroy();
        global.pieChart.setup();
    }

    /*setupPodcastPlayer(){
        global.podcastPlayer.destroy();
        global.podcastPlayer.setup();
    }*/

    setupVideoOverlay(){
        global.videoOverlay.destroy();
        global.videoOverlay.setup();
    }

    setupBlogElements(){
        //setup blog footer
        $('.js-ViewMoreFromAuthorJump').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            global.utils.scrollTo($('.moreFromBlock'),174);

            return false;
        });

        //check to open blog menu
        if(!this.blogNavOpened){
            if($('body').hasClass('page-ourBlogList')){
                this.blogNavOpened = true;
                /*setTimeout(function(){
                    global.blogNavigation.openBlogNav(true);
                }, 1000);*/
            }
        }

        //setup register link from social media summary
        //could actually be from anywhere on site block is placed
        $('.js-socialMediaRegisterLink').on('click', function(e) {
            if($('body').hasClass('page-ourBlogList') || $('body').hasClass('page-ourBlog')){
                e.stopPropagation();
                e.preventDefault();

                global.blogNavigation.showSignupTab();
                global.blogNavigation.openBlogNav(true);

                return false;
            }else{
                //linked from elsewhere in the site, follow link
            }
        });

        $('.js-blogRemains-moreLink').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();

            $(this).parent().parent().addClass('open');

            return false;
        });

        $('.doNextLinks-link.comments-link').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();

            global.utils.scrollTo($('#comments'),174);

            return false;
        });

        $('.asideTags a').hover(function(){
            $(this).parent().parent().addClass('hover');
        },function(){
            $(this).parent().parent().removeClass('hover');
        });
    }

    setupCollapsibleNavs(){
        let scope = this;

        $('.mobileCollapseNav').each(function(){
            var nav = $(this);
            nav.find('.mobileCollapseNav-link').on('click',function(e){
                scope.collapsibleNavClick(nav, e);
            });
        });

        $('.mobileCollapseNav-content a').hover(function(){
            $(this).parent().parent().addClass('hover');
        },function(){
            $(this).parent().parent().removeClass('hover');
        });
    }

    collapsibleNavClick(nav, e){
        e.preventDefault();
        e.stopPropagation();

        var cont = nav.find('.mobileCollapseNav-content');

        if(nav.hasClass('opened')){
            if(global.viewportWidth < 915){
                Velocity(cont, "stop");
                Velocity(cont, "slideUp", 300);
                nav.toggleClass('opened');
            }
        }else{
            Velocity(cont, "stop");
            Velocity(cont, "slideDown", 400);
            nav.toggleClass('opened');
        }

        return false;
    }

    setupGalleries() {

        let scope = this;

        // content galleries
        if($('.gallery').length > 0){
            $('.gallery').each(function(){

                const uniqueClassName = 'gallery-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.gallerySlide',
                    setGallerySize: false,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: true,
                    pageDots: '>1',
                    autoPlay: true
                });
                gallery.resize();

            });
        }

        this.setupLinkBlockGalleries();
        this.setupTimeLineGalleries();
        this.setupClientGalleries();
        this.setupBlogAuthorGalleries();
        this.setupEducationRegistrationGalleries();
        this.setupCasestudyIntroGalleries();

        var themedBlogGallery = null;
        if($('.simple-gallery').length > 0){
            $('.simple-gallery').each(function(){
                themedBlogGallery = new Flickity('.simple-gallery', {
                    cellSelector: '.simple-gallerySlide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: true,
                    pageDots: false,
                    autoPlay: false,
                    draggable: true,
                    wrapAround: true,
                    cellAlign: 'center',
                    arrowShape: scope.galleryArrow
                });
                themedBlogGallery.resize();
            });
        }

        // downloadtools
        var downloadToolsGallery = null;
        if($('.downloadtoolsgallery').length > 0){
            $('.downloadtoolsgallery').each(function(){
                downloadToolsGallery = new Flickity('.downloadtoolsgallery', {
                    cellSelector: '.download-tool',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: true,
                    autoPlay: false,
                    draggable: true,
                    wrapAround: false,
                    cellAlign: 'center'
                });
                downloadToolsGallery.resize();
                scope.setupCarouselListeners($(this), downloadToolsGallery);
            });
        }

        // services gallery on about page
        var servicesGallery = null;
        if($('.pageContentBlockSlides').length > 0){
            $('.pageContentBlockSlides').each(function(){
                servicesGallery = new Flickity('.pageContentBlockSlides', {
                    cellSelector: '.pageContentBlock-content',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false,
                    draggable: false
                });
                servicesGallery.resize();
            });
        }
        //new service gallery - text
        var newServicesGalleryText = null;
        if($('.serviceCopySlides').length > 0){
            $('.serviceCopySlides').each(function(){
                newServicesGalleryText = new Flickity('.serviceCopySlides', {
                    cellSelector: '.serviceCopySlide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false,
                    draggable: false,
                    accessibility: false,
                    fade: true,
                    initialIndex: $(this).find('.serviceCopySlide').length -1
                });
                newServicesGalleryText.resize();
            });
        }
        //new service gallery - icons
        var newServicesGalleryIcons = null;
        if($('.servicesIcons').length > 0){
            $('.servicesIcons').each(function(){
                newServicesGalleryIcons = new Flickity('.servicesIcons', {
                    cellSelector: '.servicesIcon',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false,
                    draggable: false,
                    accessibility: false,
                    cellAlign: 'center',
                    initialIndex: $(this).find('.servicesIcon').length -1
                });
                newServicesGalleryIcons.resize();
                newServicesGalleryIcons.on( 'change', function() {
                    newServicesGalleryIcons.reposition();
                });
            });
        }

        // links for services gallery on about page
        if($('.aboutServices-nav').length > 0){
            $('.aboutServices-nav').each(function(){
                $(this).find('.aboutServices-link').each(function(i){
                    $(this).on('click',function(e){
                        e.preventDefault();
                        e.stopPropagation();

                        $(this).parent().parent().find('.selected').removeClass('selected');
                        $(this).addClass('selected');
                        if(servicesGallery)servicesGallery.select(i, false, true);
                        if(newServicesGalleryText)newServicesGalleryText.select(i, false, true);
                        if(newServicesGalleryIcons)newServicesGalleryIcons.select(i, false, false);

                        if($(this).hasClass('aboutServices-link-all')){
                            $('.servicesAnimation').fadeIn();
                            $('.servicesIcons').hide();
                            global.resizing.resizeHandler();
                        }else{
                            $('.servicesAnimation').hide();
                            if($('.servicesIcons').css('display') == 'none'){
                                $('.servicesIcons').fadeIn();
                                global.resizing.resizeHandler();
                                newServicesGalleryIcons.resize();
                            }
                        }

                        if(global.viewportWidth < 915){
                            Velocity($('.mobileCollapseNav-content'), "stop");
                            Velocity($('.mobileCollapseNav-content'), "slideUp", 300);
                            $('.mobileCollapseNav').removeClass('opened');
                        }

                        var anchor = $('.primaryServicesPane').data('anchor');
                        global.viewportScrolling.gotoSection(anchor);

                        $('.serviceCaseStudyGroups .serviceCaseStudyGroup').hide();
                        $('.serviceCaseStudyGroups').each(function(){
                            $(this).find('.serviceCaseStudyGroup').each(function(j){
                                if(j == i){
                                    $(this).show();
                                    global.resizing.resizeHandler();
                                    Flickity.data('.flickity-enabled').resize();
                                }
                            });
                        });

                        return false;
                    });
                });
            });
        }
        $('.servicesCaseStudyLink').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.viewportScrolling.gotoNextSlide();

            return false;
        });
    }

    setupCarouselListeners($el, flkty){

        // Prevent page scrolling on touch devices when dragging the carousel
        flkty.on('dragStart', function( event, pointer ) {
            $el.find('div').css('pointer-events', 'none');
            document.ontouchmove = function (e) {
                e.preventDefault();
            }
        });
        flkty.on('dragEnd', function( event, pointer ) {
            $el.find('div').css('pointer-events', 'all');
            document.ontouchmove = function (e) {
                return true;
            }
        });

    }

    setupTimeLineGalleries(){

        let scope = this;

        //linkblocks
        if($('.timeline-holder-mobile').length > 0){
            $('.timeline-holder-mobile').each(function(){

                const uniqueClassName = 'timeline-holder-mobile-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.timeline-slide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false
                });
                gallery.resize();

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }
    }

    setupClientGalleries(){

        let scope = this;

        //linkblocks
        if($('.clientLogosMobile').length > 0){
            $('.clientLogosMobile').each(function(){

                const uniqueClassName = 'clientLogosMobile-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.logoSlide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false
                });
                gallery.resize();
                setTimeout(gallery.resize,1000);
                setTimeout(gallery.resize,2000);
                setTimeout(gallery.resize,3000);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }
    }

    setupBlogAuthorGalleries(){

        let scope = this;

        if($('.blogAuthor-holder').length > 0){
            $('.blogAuthor-holder').each(function(){

                const uniqueClassName = 'blogAuthor-holder-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.blogAuthor-slide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false,
                    groupCells: true
                });
                gallery.resize();
                setTimeout(gallery.resize,1000);
                setTimeout(gallery.resize,2000);
                setTimeout(gallery.resize,3000);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }

        if($('.blogAuthors-holder').length > 0){
            $('.blogAuthors-holder').each(function(){

                const uniqueClassName = 'blogAuthors-holder-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.blogAuthor-slide',
                    setGallerySize: true,
                    groupCells: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false,
                    cellAlign: 'left'
                });
                gallery.resize();
                setTimeout(gallery.resize,1000);
                setTimeout(gallery.resize,2000);
                setTimeout(gallery.resize,3000);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }
    }

    setupEducationRegistrationGalleries(){

        let scope = this;

        //linkblocks
        if($('.mobileSlides').length > 0){
            $('.mobileSlides').each(function(){

                const uniqueClassName = 'mobileSlides-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.mobileSlide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false
                });
                gallery.resize();
                scope.flickities.push(gallery);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }

        $('.registrationMobileLink').on('click',function(){
            Velocity($('.registrationCopy').toArray(), "slideUp", 200);
            var slideCont = $(this).parent().find('.registrationCopy')[0];
            if($(this).parent().hasClass('open')){
                $(this).parent().removeClass('open');
                Velocity(slideCont, "stop");
                Velocity(slideCont, "slideUp", 300);
            }else{
                $('.registration-details-holder .open').removeClass('open');
                $(this).parent().addClass('open');
                Velocity(slideCont, "stop");
                Velocity(slideCont, "slideDown", 400);
            }
        });

    }

    setupCasestudyIntroGalleries(){

        let scope = this;

        //linkblocks
        if($('.caseStudyIntro-holder-mobile').length > 0){
            $('.caseStudyIntro-holder-mobile .mobileContentSlider').each(function(){

                const uniqueClassName = 'caseStudyIntro-holder-mobile-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.mobileContentSlide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false
                });
                gallery.resize();
                scope.flickities.push(gallery);

            });
        }
    }

    setupLinkBlockGalleries(){

        let scope = this;

        //linkblocks
        if($('.featuredBlock-cols-mobile').length > 0){
            $('.featuredBlock-cols-mobile').each(function(){

                const uniqueClassName = 'featuredBlock-cols-mobile-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.featuredBlock-col',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: '>1',
                    autoPlay: false,
                    groupCells: true
                });
                gallery.resize();
                scope.flickities.push(gallery);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }
    }

    updateNavigation() {
        if($('.privacy-nav').length > 0){
            $('.privacy-nav a').each(function(){
                $(this).on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    if($(this).hasClass('firstLink')){
                        global.utils.scrollToTop();
                    }else{
                        var privacySection = $(this).attr('href');
                        global.utils.scrollTo($(privacySection));
                    }

                    return false;
                });
            });
        }

        //update sharing icons on case study / blog
        if($('body').hasClass('page-caseStudy') || $('body').hasClass('page-ourBlogList') || $('body').hasClass('page-ourBlog')){
            var twitterLink = $('.pageTrack').data('share-twitter');
            var facebookLink = $('.pageTrack').data('share-facebook');
            var linkedinLink = $('.pageTrack').data('share-linkedin');

            $('.socialOverlay-icon-twitter').attr('href',twitterLink);
            $('.socialOverlay-icon-facebook').attr('href',facebookLink);
            $('.socialOverlay-icon-linkedin').attr('href',linkedinLink);
        }

        /*if($('body').hasClass('page-blogs') || $('body').hasClass('page-ourBlogList') || $('body').hasClass('page-ourBlog')){
            $('.logo.home-link').attr('href','/blog');
        }else{
            $('.logo.home-link').attr('href','/');
        }*/
    }

    setupDelayedLoads(){
        let scope = this;
        if($('.delayedDisplay').length > 0){
            $('.delayedDisplay').each(function(){
                scope.delayedDisplayCnt++;
                const uniqueClassName = 'delayedDisplay-'+scope.delayedDisplayCnt;
                const el = $(this);
                $(this).addClass(uniqueClassName);
                inView('.'+uniqueClassName).on('enter', function(){
                    if(el.data('delayed-display-delay')){
                        el.css('transition-delay',el.data('delayed-display-delay')+'s');
                    }
                    el.addClass('delayedDisplayed');
                });
            });
        }

        if($('.delayedDisplayCTA').length > 0){
            $('.delayedDisplayCTA').each(function(){
                scope.delayedDisplayCnt++;
                const uniqueClassName = 'delayedDisplay-'+scope.delayedDisplayCnt;
                const el = $(this);
                $(this).addClass(uniqueClassName);
                inView('.'+uniqueClassName).on('enter', function(){
                    if(el.data('delayed-display-delay')){
                        el.css('transition-delay',el.data('delayed-display-delay')+'s');
                    }
                    el.addClass('delayedCTADisplayed');
                });
            });
        }
    }

    setupChartInteractions(){
        $('.contentBlock-graph-arches .mobileGraph').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();

            if($(this).find('.lines path').length > 3){
                if($(this).hasClass('chartScrolled')){
                    $(this).removeClass('chartScrolled');
                }else{
                    $(this).addClass('chartScrolled');
                }
            }

            return false;
        });
    }

    setupChartAnimations(){
        let scope = this;
        $('.animatedSVG').each(function(){
            scope.animatedSVGCnt++;
            const uniqueID = 'animatedSVG-'+scope.animatedSVGCnt;
            $(this).attr('id', uniqueID);
            var v = new Vivus(uniqueID, {duration: 170, pathTimingFunction:Vivus.EASE, animTimingFunction:Vivus.EASE_OUT});
            $(this).data('vivus',v);
            inView('#'+uniqueID).on('enter', function(){
                v.play();
            });
        });
    }

    setupChartGalleries(){
        let scope = this;

        if($('.graphSlider').length > 0){
            $('.graphSlider').each(function(){

                const uniqueClassName = 'graphSlider-'+scope.galCnt;
                scope.galCnt++;
                $(this).addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.graphSlider-slide',
                    setGallerySize: true,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false
                });
                gallery.resize();

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

            });
        }
    }

    resizeCarousels(){
        this.flickities.forEach( function( flkty ) {
            flkty.resize();
        });
    }

    setupGrid() {

    }

    setupForms() {
        //About Contact Form
        if($('#aboutForm').length > 0){
            $('#aboutForm').submit(function(e) {
                e.preventDefault();
                e.stopPropagation();

                $('#aboutForm .sub-button').hide();
                $('#aboutForm .form-submitting').show();
                $('#aboutForm .form-confirm').hide();
                $('#aboutForm .form-error').hide();
                $('#aboutForm .linkArrow').hide();

                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $(this).serialize(),
                    success: function(response) {
                        if (response.success) {
                            $('#aboutForm .form-submitting').hide();
                            $('#aboutForm .form-confirm').show();
                        } else {
                            console.log(response);
                            $('#aboutForm .form-submitting').hide();
                            $('#aboutForm .form-error').show();

                            $('#aboutForm .sub-button').show();
                            $('#aboutForm .linkArrow').show();
                        }
                    },
                    error: function(error){
                        console.log(error);
                        $('#aboutForm .form-submitting').hide();
                        $('#aboutForm .form-error').show();

                        $('#aboutForm .sub-button').show();
                        $('#aboutForm .linkArrow').show();
                    }
                });
            });
        }

        if($('.mailChimpForm').length > 0){
            $('.mailChimpForm').each(function(){
                var $mcForm = $(this);

                $mcForm.on("submit", function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    $mcForm.find('.sub-button').hide();
                    $mcForm.find('.form-submitting').show();
                    $mcForm.find('.form-confirm').hide();
                    $mcForm.find('.form-error').hide();
                    $mcForm.find('.linkArrow').hide();

                    $.ajax({url: '/',
                        type: "POST",
                        data: $(this).serialize(),
                        dataType:"json",
                        success: function(response) {
                            if (response.success) {
                                $mcForm.find('.form-submitting').hide();
                                $mcForm.find('.form-confirm').show();
                            } else {
                                console.log(response);
                                $mcForm.find('.form-submitting').hide();
                                $mcForm.find('.form-error').show();

                                $mcForm.find('.sub-button').show();
                                $mcForm.find('.linkArrow').show();
                            }
                        },
                        error: function(error){
                            console.log(error);
                            $mcForm.find('.form-submitting').hide();
                            $mcForm.find('.form-error').show();

                            $mcForm.find('.sub-button').show();
                            $mcForm.find('.linkArrow').show();
                        }
                    })
                });
            });
        }
    }
}
