const scrollOverflow = require("fullpage2.js/vendors/scrolloverflow.js");
const fullpage = require("fullpage2.js/dist/jquery.fullpage.js");

export class ViewportScrolling {
    constructor() {
        this.setupDone = false;
        this.sectionClassNames = "";
    }

    setup() {
        //setup

        global.resizing.resizeHandler();

        let scope = this;
        if($('.scrollSlides').length > 0){
            $('html').removeClass('no-fp');
            $('.scrollSlides').fullpage({
                slidesNavigation: true,
                slidesNavPosition: 'bottom',

                //Scrolling
                css3: true,
                scrollingSpeed: 700,
                autoScrolling: true,
                fitToSection: true,
                fitToSectionDelay: 1000,
                scrollBar: false,
                easing: 'easeInOutCubic',
                easingcss3: 'ease',
                loopBottom: false,
                loopTop: false,
                loopHorizontal: false,
                continuousVertical: false,
                continuousHorizontal: false,
                //dragAndMove: 'horizontal',
                //dragAndMoveKey: '',
                offsetSections: false,
                resetSliders: false,
                fadingEffect: false,
                //normalScrollElements: '.normal-scroll',
                scrollOverflow: true,
                scrollOverflowOptions: {
                    click: false,
                    preventDefaultException: { tagName:/.*/ }
                },

                //Accessibility
                keyboardScrolling: true,
                animateAnchor: false,
                recordHistory: false,

                //Design
                controlArrows: true,
                verticalCentered: false,
                paddingTop: '0px',
                paddingBottom: '0px',
                responsiveWidth: 355,
                responsiveHeight: 550,//450,
                resize: true,

                lazyLoading: false,

                //events
                onLeave: function(index, nextIndex, direction){
                    var section = $('.scrollSlides').eq(0).find('.section').eq(index - 1);
                    section.find('video').each(function(){
                        this.pause();
                    });

                    var nextSection = $('.scrollSlides').eq(0).find('.section').eq(nextIndex - 1);
                    var nextSectionClass = nextSection.data('slide-class') || "";

                    scope.updateClasses();
                    if(nextSectionClass != ""){
                        $('body').addClass('fp-'+nextSectionClass);
                    }
                },
                afterLoad: function(anchorLink, index){
                    var section = $('.scrollSlides').eq(0).find('.section').eq(index - 1);
                    var sectionClass = section.data('slide-class') || "";
                    section.find('video').each(function(){
                        this.play();
                    });

                    if(sectionClass != ""){
                        $('body').addClass('fp-'+sectionClass);
                    }
                },
                afterRender: function(){
                    setTimeout(function(){
                        scope.rebuildScrolling;
                    },100);
                },
                afterResize: function(){
                    global.resizing.resizeHandler();
                },
                //afterResponsive: function(isResponsive){},
                //afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){},
                //onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){}
            });

            this.setupDone = true;
        }else{
            $('html').addClass('no-fp');
        }
    }

    rebuildScrolling(){
        if(this.setupDone){
            $('.scrollSlides').fullpage.reBuild();
        }
    }

    gotoNextSection(){
        $.fn.fullpage.moveSectionDown();
    }
    gotoNextSlide(){
        $.fn.fullpage.moveSlideRight();
    }
    gotoSection(i){
        $.fn.fullpage.moveTo(i);
    }

    updateClasses(){
        // bodge to keep fp-responsive
        var responsive = false;
        if($('body').hasClass('fp-responsive')){
            responsive = true;
        }
        this.removeFPClasses();
        if(responsive){
            $('body').addClass('fp-responsive');
        }
    }

    removeFPClasses(){
        // removes any class on body that starts with "fp-"
        $('body').removeClass(function (index, className) {
            return (className.match (/(^|\s)fp-\S+/g) || []).join(' ');
        });
    }

    destroy() {
        if(this.setupDone){
            //destroy / cleanup

            this.sectionClassNames = "";
            $('html').removeClass('no-fp');
            if($('html').hasClass('fp-enabled')){
                $.fn.fullpage.destroy('all');
                $('html').removeClass('fp-enabled');
            }
            this.removeFPClasses();

            this.setupDone = false;
        }
    }

}
