export class Hotjar {
    constructor() {
      this.trackingId = "Hotjar";
      this.trackingSetup = false;
      this.trackingScript = "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:1826790,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    }

    setup() {
      if(!this.trackingSetup){
        console.log(this.trackingId+' Setup');
        this.intialiseHotJar();
      }
    }

    intialiseHotJar(){
        // Initialise HotJar Tracking if we should (according to cookies)
        if(global.cookieAllowStats){
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = this.trackingScript;
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            console.info(this.trackingId+' Initialised');
            this.trackingSetup = true;
        }else{
            console.info(this.trackingId+' Not Initialised');
        }
    }

}
