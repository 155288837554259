// Penner easing functions refactored to take a single normalised
// linear time value of between 0 and 1, returning a normalised eased time value.

// This is to abstract the easing functions away from actually tweening
// numerical values so that they can be more easily and efficently used
// as the time component in existing lerp/slerp functions.

let t = 0, b = 0, c = 1, d = 1

let outBounce;
let inBounce;

export const Easing = {
  'linear'(t) {
    return t;
  },
  'in-quad'(t) {
    return t * t;
  },
  'out-quad'(t) {
    return -1 * t * (t -2);
  },
  'in-out-quad'(t) {
    if ((t/=.5) < 1) return .5*t*t;
  		return -.5 * ((--t)*(t-2) - 1);
  },
  'in-cubic'(t) {
    return t * t * t;
  },
  'out-cubic'(t) {
    t = t - 1;
    return t * t * t + 1;
  },
  'in-out-cubic'(t) {
    if ((t/=.5) < 1) return .5*t*t*t
  		return .5*((t-=2)*t*t + 2);
  },
  'in-quart'(t) {
    return t * t * t * t;
  },
  'out-quart'(t) {
    t = t - 1;
    return -1 * (t * t * t * t - 1);
  },
  'in-out-quart'(t) {
    if ((t/=.5) < 1) return .5*t*t*t*t;
  	return -.5 * ((t-=2)*t*t*t - 2);
  },
  'in-quint'(t) {
    return t * t * t * t * t;
  },
  'out-quint'(t) {
    t = t - 1;
    return (t * t * t * t * t + 1);
  },
  'in-out-quint'(t) {
    if ((t/=.5) < 1) return .5*t*t*t*t*t;
  	return .5*((t-=2)*t*t*t*t + 2);
  },
  'in-sine'(t) {
    return -1 * Math.cos(t * (Math.PI / 2)) + 1;
  },
  'out-sine'(t) {
    return Math.sin(t * (Math.PI / 2));
  },
  'in-out-sine'(t) {
    return (Math.cos(Math.PI * t) - 1) / -2;
  },
  'in-expo'(t) {
    return 2 ** (10 * (t - 1));
  },
  'out-expo'(t) {
    return -(2 ** (-10 * t)) + 1;
  },
  'in-out-expo'(t) {
    if (t==0) return b;
  	if (t==1) return 1;
  	if ((t/=.5) < 1) return .5 * (2 ** (10 * (t - 1)));
  	return .5 * (-(2 ** (-10 * --t)) + 2);
  },
  'in-circ'(t) {
    return -1 * (Math.sqrt(1 - t * t) - 1);
  },
  'out-circ'(t) {
    t = t - 1;
    return Math.sqrt(1 - t * t);
  },
  'in-out-circ'(t) {
    const c = 1;
    if ((t/=.5) < 1) return -.5 * (Math.sqrt(1 - t*t) - 1);
  		return .5 * (Math.sqrt(1 - (t-=2)*t) + 1);
  },
  'in-back'(t, overshoot) {
    if (!overshoot && overshoot !== 0){
      overshoot = 1.70158;
    }
    return 1 * t * t * ( (overshoot + 1) * t - overshoot );
  },
  'out-back'(t, overshoot) {
    if(!overshoot && overshoot !== 0){
      overshoot = 1.70158;
    }
    t = t - 1;
    return t * t * ((overshoot + 1) * t + overshoot) + 1;
  },
  'in-out-back'(t, overshoot) {
    if (overshoot == undefined) overshoot = 1.70158;
  	if ((t/=.5) < 1) return .5*(t*t*(((overshoot*=(1.525))+1)*t - overshoot));
  	return .5*((t-=2)*t*(((overshoot*=(1.525))+1)*t + overshoot) + 2);
  },
  'in-bounce'(t) {
    return 1 - outBounce(1 - t);
  },
  'out-bounce'(t) {
    if (t < 0.36363636363636365) {
      return 7.5625 * t * t;
    } else if (t < 0.7272727272727273) {
      t = t - 0.5454545454545454;
      return 7.5625 * t * t + 0.75;
    } else if (t < 0.9090909090909091) {
      t = t - 0.8181818181818182;
      return 7.5625 * t * t + 0.9375;
    } else {
      t = t - 0.9545454545454546;
      return 7.5625 * t * t + 0.984375;
    }
  },
  'in-out-bounce'(t) {
    if (t < 0.5){
      return inBounce (t*2) * 0.5;
    }
    return outBounce ( t*2-1 ) * 0.5 + 1 * 0.5;
  },
  'in-elastic'(t, amplitude, period) {
    if (typeof period == 'undefined') {
      period = 0;
    }
    if (typeof amplitude == 'undefined'){
      amplitude = 1;
    }
    let offset = 1.70158;

    if (t == 0) return 0;
    if (t == 1) return 1;

    if (!period){
      period = .3;
    }

    if (amplitude < 1){
      amplitude = 1;
      offset = period / 4;
    } else {
      offset = period / (2*Math.PI) * Math.asin(1 / amplitude);
    }

    return -(amplitude*(2 ** (10 * (t -= 1))) * Math.sin( (t - offset) * (Math.PI * 2) / period));
  },
  'out-elastic'(t, amplitude, period) {
    if (typeof period == 'undefined') {
      period = 0;
    }
    if (typeof amplitude == 'undefined'){
      amplitude = 1;
    }
    let offset = 1.70158;

    if (t == 0) return 0;
    if (t == 1) return 1;

    if (!period){
      period = .3;
    }

    if (amplitude < 1){
      amplitude = 1;
      offset = period / 4;
    } else {
      offset = period / (2*Math.PI) * Math.asin(1 / amplitude);
    }

    return amplitude * (2 ** (-10 * t)) * Math.sin( (t - offset) * (Math.PI * 2) / period ) + 1;
  },
  'in-out-elastic'(t, amplitude, period) {
    let offset;
    t = (t / 2) - 1;
    // escape early for 0 and 1
    if (t === 0 || t === 1) {
      return t;
    }
    if (!period){
      period = 0.44999999999999996;
    }
    if (!amplitude){
      amplitude = 1;
      offset = period / 4;
    } else {
      offset = period / (Math.PI * 2.0) * Math.asin(1 / amplitude);
    }
    return (amplitude * (2 ** (10 * t)) * Math.sin((t - offset) * (Math.PI * 2) / period )) / -2;
  }
};

outBounce = Easing['out-bounce'];
inBounce = Easing['in-bounce'];
