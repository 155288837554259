const Velocity = require("velocity-animate");

export class CookieNotification {
    constructor() {
      global.cookieVersion = 1;
      global.cookiesSet = false;
      global.cookieAllowStats = false;
      global.cookieAllowPrefs = false;
      global.cookieAllowMarketing = false;
    }

    setup() {
      console.log("Setup Cookies");

      this.checkForCookies();
    }

    checkForCookies(){
      if(global.utils.getCookie("WeAreBroCS"+global.cookieVersion)){

        console.log("Cookies Enabled");
        global.cookiesSet = true;
        if(global.utils.getCookie("WeAreBroStats"+global.cookieVersion)){
          global.cookieAllowStats = true;
        }
        if(global.utils.getCookie("WeAreBroPrefs"+global.cookieVersion)){
          global.cookieAllowPrefs = true;
        }
        if(global.utils.getCookie("WeAreBroMarketing"+global.cookieVersion)){
          global.cookieAllowMarketing = true;
        }

        $('.cookie-notification').remove();
        global.tracking.setupTracking();

      }else{

        console.log("Cookies Not Enabled");
        this.reset();
        this.showNotification();

      }
    }

    showNotification(){
      console.log("Show Cookie Notification");
      let scope = this;
      $('.cookie-notification').css('pointer-events', 'auto');

      //$('.cookie-contents').velocity('stop').velocity("fadeIn", { duration: 1 });
      //$('.cookie-confirm').velocity('stop').velocity("fadeOut", { duration: 1 });
      //$('.cookie-notification').velocity('stop').velocity("slideUp", { duration: 500 });

      $('.cookie-contents').css('opacity',1);
      $('.cookie-confirm').css('opacity',0);
      Velocity($('.cookie-notification')[0], "slideDown", {duration:500});

      //Accept Necessary only
      $('.cn-btn-accept-nec').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.acceptNecessaryOnly(scope);
            scope.hideNotification();

            return false;
      });

      //Accept All
      $('.cn-btn-accept-all').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.acceptAll(scope);
            scope.hideNotification();

            return false;
      });

      //Accept Selection
      $('.cn-btn-accept-selection').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.acceptSelected(scope);
            scope.hideNotification();

            return false;
      });
    }
    hideNotification(){
      console.log("Hide Cookie Notification");
      $('.cookie-notification').css('pointer-events', 'none');

      var confirmAnimTime = 1700;

      $('.cookie-contents').css('opacity',0);
      $('.confirm-anim').css('opacity',0);
      $('.cookie-confirm').css('opacity',0);
      setTimeout(function(){
        $('.cookie-confirm').css('opacity',1);
      },251);
      setTimeout(function(){
        $('.confirm-anim').css('opacity',1);
      },500);

      setTimeout(function(){
        global.tracking.setupTracking();
      },confirmAnimTime-100);

      setTimeout(function(){
        $('.confirm-anim').css('opacity',0);
        $('.cookie-confirm').css('opacity',0);
      },confirmAnimTime);

      Velocity($('.cookie-notification')[0], "slideUp", {delay:(confirmAnimTime+700), duration:300} );

      if(!global.stagingMode){
        setTimeout(function(){
          $('.cookie-notification').remove();
        },(1500 + confirmAnimTime));
      }
    }

    acceptNecessaryOnly(scope){
      scope.setCookiesStates(true,false,false,false);
    }
    acceptAll(scope){
      scope.setCookiesStates(true,true,true,true);
    }
    acceptSelected(scope){
      var statsAccepted = false;
      var prefsAccepted = false;
      var marketingAccepted = false;

      if($('#cookie-statistics').prop("checked") == true){
        statsAccepted = true;
      }
      if($('#cookie-prefs').prop("checked") == true){
        prefsAccepted = true;
      }
      if($('#cookie-marketing').prop("checked") == true){
        marketingAccepted = true;
      }

      scope.setCookiesStates(true, statsAccepted, prefsAccepted, marketingAccepted);
    }

    setCookiesStates(cookies, stats, prefs, marketing){
      if(cookies == true || stats == true || prefs == true || marketing == true){
        global.cookiesSet = true;
        global.utils.setCookie("WeAreBroCS"+global.cookieVersion, 1, 1);
      }else{
        global.cookiesSet = false;
        global.utils.deleteCookie("WeAreBroCS"+global.cookieVersion);
      }

      if(stats == true){
        global.cookieAllowStats = true;
        global.utils.setCookie("WeAreBroStats"+global.cookieVersion, 1, 1);
      }else{
        global.cookieAllowStats = false;
        global.utils.deleteCookie("WeAreBroStats"+global.cookieVersion);
      }

      if(prefs == true){
        global.cookieAllowPrefs = true;
        global.utils.setCookie("WeAreBroPrefs"+global.cookieVersion, 1, 1);
      }else{
        global.cookieAllowPrefs = false;
        global.utils.deleteCookie("WeAreBroPrefs"+global.cookieVersion);
      }

      if(marketing == true){
        global.cookieAllowMarketing = true;
        global.utils.setCookie("WeAreBroMarketing"+global.cookieVersion, 1, 1);
      }else{
        global.cookieAllowMarketing = false;
        global.utils.deleteCookie("WeAreBroMarketing"+global.cookieVersion);
      }

      console.log("[Updating Cookies] CS:"+global.cookiesSet+" Stats:"+global.cookieAllowStats+" Prefs:"+global.cookieAllowPrefs+" Marketing:"+global.cookieAllowMarketing);
    }

    reset(){

      global.cookiesSet = false;
      global.cookieAllowStats = false;
      global.cookieAllowPrefs = false;
      global.cookieAllowMarketing = false;
      global.utils.deleteCookie("WeAreBroCS"+global.cookieVersion);
      global.utils.deleteCookie("WeAreBroStats"+global.cookieVersion);
      global.utils.deleteCookie("WeAreBroPrefs"+global.cookieVersion);
      global.utils.deleteCookie("WeAreBroMarketing"+global.cookieVersion);

    }

}
