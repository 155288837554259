require("./styles/main.scss");

import { Utilities } from "./scripts/Utilities";

import { DocumentReady } from "./scripts/loading/1DocumentReady";
import { PageReady } from "./scripts/loading/2PageReady";
import { InitPage } from "./scripts/loading/3InitPage";
import { PageLoading } from "./scripts/loading/PageLoading";

import { Tracking } from "./scripts/tracking/Tracking";
import { Hotjar } from "./scripts/tracking/thirdParty/Hotjar";
import { Linkedin } from "./scripts/tracking/thirdParty/Linkedin";
import { GTag } from "./scripts/tracking/thirdParty/GTag";
import { Sleeknote } from "./scripts/tracking/thirdParty/Sleeknote";

import { CookieNotification } from "./scripts/cookies/CookieNotification";
import { BlogNavigation } from "./scripts/navigation/BlogNavigation";

import { Scrolling } from "./scripts/layout/Scrolling";
import { Resizing } from "./scripts/layout/Resizing";

import { ViewportScrolling } from "./scripts/layout/ViewportScrolling";
import { IntroSlider } from "./scripts/introSlider/IntroSlider";
import { AnimatedTitle } from "./scripts/animatedTitle/AnimatedTitle";

import { PieChart } from "./scripts/graphs/PieChart";

import { VideoOverlay } from "./scripts/contentBlocks/VideoOverlay";
import "./scripts/comments";

const startDate = new Date();

global.utils = new Utilities();

global.documentReady = new DocumentReady();
global.pageReady = new PageReady();
global.initPage = new InitPage();
global.pageLoading = new PageLoading();

global.tracking = new Tracking();
global.hotjar = new Hotjar();
global.linkedin = new Linkedin();
global.gtag = new GTag();
global.sleeknote = new Sleeknote();

global.cookieNotification = new CookieNotification();
global.blogNavigation = new BlogNavigation();

global.scrolling = new Scrolling();
global.resizing = new Resizing();

global.viewportScrolling = new ViewportScrolling();
global.introSlider = new IntroSlider();
global.animatedTitle = new AnimatedTitle();

global.pieChart = new PieChart();

global.videoOverlay = new VideoOverlay();


document.addEventListener("DOMContentLoaded", function() {
    documentReady.setupPageClasses();
    documentReady.setupNavigationLinks();
    documentReady.setupKeyboardEvents();

    scrolling.setup();
    resizing.setup();

    cookieNotification.setup();
    blogNavigation.setup();

    // scrolling.addEventListener("scroll", (yPos) => {
    //     global.pieChart.onScrollHandler(ypos);
    // })

    if(!$('html').hasClass('ie') && window.location.href.indexOf("wearebro.dk") > -1){
        pageLoading.setupBarba();
    }
    global.stagingMode = false;
    if(window.location.href.indexOf("staging.wearebro.dk") > -1){
      global.stagingMode = true;
    }
});

window.onload = function() {
    global.pageReady.setup();
    global.initPage.setup();
};

const endDate = new Date();
const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
console.log('App started in '+seconds+' seconds');
