const easing = require('../Easing').Easing
const rangesliderJs = require('rangeslider.js')

export class IntroSlider {
    constructor() {
        this.setupDone = false

        this.intervals = 3
        this.currentIndex = -1

        this.targetPos = null
        this.initPos = null
        this.time = null
        this.rafId = null

        this.rs = null
    }

    initRangeSlider() {
        this.$slider.rangeslider({
            polyfill: false,
            // Default CSS classes
            rangeClass: 'rangeslider',
            disabledClass: 'rangeslider--disabled',
            horizontalClass: 'rangeslider--horizontal',
            verticalClass: 'rangeslider--vertical',
            fillClass: 'rangeslider__fill',
            handleClass: 'rangeslider__handle',
            onInit: this.handleInit.bind(this),
            onSlide: this.handleSlide.bind(this),
            onSlideEnd: this.handleEnd.bind(this)
        })
    }

    setup(setupDelay = 850) {
        let scope = this;

        this.$slider = $('.js-slider input[type="range"]')
        this.$imageSets = $('.js-sliderSets > div')

        this.intervals = $('.introSlider-contentSets-set').length - 1

        if (this.$imageSets.length > 0 && this.$slider.length > 0) {

            this.rangeMax = parseInt(this.$slider.attr('max'))
            this.intervalSteps = Math.floor(this.rangeMax / this.intervals)

            this.initRangeSlider()

            setTimeout(() => {
                $('.js-slider').addClass('is-inited')
                $('.js-sliderSets').addClass('is-inited')
                this.$slider.rangeslider('update', !0)

                this.gotoMiddleSlide();
            }, setupDelay)

            setTimeout(() => {
                this.removeBounceAnim();
            }, setupDelay + 6000)

            this.$imageSets.each((i, elSet) => {
                const imgs = $('> div', elSet)
                const total = imgs.length;
                // imgs.sort(function () { return 0.5 - Math.random() }) // Shuffle array.
                imgs.each((i, elImg) => {
                    // TODO: Add more delays to css;
                    const increment = 2
                    var delay = ''+parseInt(increment * (i + 1)) / 10;
                    if(delay.charAt(0) == 0){
                        delay = delay.substring(1);
                    }
                    $(elImg).attr('data-animation-delay', delay);
                })
            })

            this.setupDone = true
        }

        $('.introSlider-content-slider-label--left').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.prevSlide();

            return false;
        });
        $('.introSlider-content-slider-label--right').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.nextSlide();

            return false;
        });
    }

    nextSlide(){
        this.removeBounceAnim();
        var index = Math.min(this.currentIndex +1, this.intervals);
        this.gotoSlide(index);
        this.initPos = this.targetPos;
    }

    gotoMiddleSlide(){
        //this.removeBounceAnim();
        var index = Math.floor(($('.introSlider-contentSets-set').length - 1)/2);
        this.gotoSlide(index);
        this.initPos = this.targetPos;
    }

    prevSlide(){
        this.removeBounceAnim();
        var index = Math.max(this.currentIndex -1, 0);
        this.gotoSlide(index);
        this.initPos = this.targetPos;
    }

    removeBounceAnim(){
        //$('.sliderBounce').removeClass('sliderBounce');
    }

    handleSlide(position, value) {
        cancelAnimationFrame(this.rafId)
        const nextIndex = Math.round(((value + 1) / this.rangeMax) * this.intervals)

        if (this.currentIndex != nextIndex) {

            this.$imageSets.removeClass('is-fadeOut')
            this.$imageSets.removeClass('is-fadeIn')

            const next = $(this.$imageSets.get(nextIndex))
            const current = $(this.$imageSets.get(this.currentIndex))

            next.attr('aria-hidden', false)
            current.attr('aria-hidden', true)

            next.addClass('is-fadeIn');
            current.addClass('is-fadeOut')

            this.currentIndex = nextIndex
        }
    }

    handleEnd(position, value) {
        const currentNearestIndex = Math.round((value / this.rangeMax) * this.intervals)
        this.initPos = value

        this.gotoSlide(currentNearestIndex);
    }

    gotoSlide(index){
        if(index > -1 && index <= this.intervals){

            this.targetPos = index * this.intervalSteps

            if (index == this.intervals) {
                this.$slider.next('.rangeslider').addClass('is-last');
            }else{
                this.$slider.next('.rangeslider').removeClass('is-last');
            }

            this.time = {
                start: performance.now(),
                total: 250
            }

            cancelAnimationFrame(this.rafId)
            this.rafId = requestAnimationFrame(this.tick.bind(this))

        }else{
            console.log("ERROR: IntroSlider out of range slide");
        }
    }

    handleInit(i, j) {
        $('.rangeslider').prepend('<div class="rangeslider__markers"></div>')
        const $markersContainer = $('.rangeslider__markers')
        for (let i = 1; i < this.intervals; i++) {
            $markersContainer.append(`<span style="left: ${i * (100 / this.intervals)}%"></span>`)
        }
    }

    tick(now) {
        this.time.elapsed = now - this.time.start
        const progress = this.time.elapsed / this.time.total

        const delta = this.initPos < this.targetPos ? this.targetPos - this.initPos : 0 - (this.initPos - this.targetPos)
        const easePos = easing['in-out-cubic'](progress)

        this.$slider.val(this.initPos + easePos * delta)
        this.$slider.rangeslider('update', !0)

        if (progress < 1) {
            this.rafId = requestAnimationFrame(this.tick.bind(this))
        }
    }

    destroy() {
        if (this.setupDone) {
            //destroy / cleanup

            this.currentIndex = -1

            this.targetPos = null
            this.initPos = null
            this.time = null
            this.rafId = null

            this.rs = null

            this.setupDone = false;
        }
    }

}
