const inView = require("in-view");

export class Scrolling {
    constructor() {
        global.prevScroll = 0;
        global.scrollDirection = 0;
    }

    setup() {
        this.scrollHandler();

        const sHandler = global.utils.throttled(100, this.scrollHandler);
        window.addEventListener("scroll", sHandler);

        let scope = this;
        setInterval(scope.scrollPosCheck, 500);
    }

    scrollHandler(e) {
        var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
        if(scrollPos > global.prevScroll){
            global.scrollDirection = 1;
        }else{
            global.scrollDirection = -1;
        }
        if(scrollPos > global.viewportHeight/2){
            $('html').addClass('afterBreak');
            $('html').removeClass('beforeBreak');
        }else{
            $('html').removeClass('afterBreak');
            $('html').addClass('beforeBreak');
        }
        global.prevScroll = scrollPos;
        if(scrollPos > 0 && global.scrollDirection > 0){
            $('html').addClass('scrollDown');
            $('html').removeClass('scrollUp');
        }else if(scrollPos > 0){
            $('html').removeClass('scrollDown');
            $('html').addClass('scrollUp');
        }else{
            $('html').removeClass('scrollDown');
            $('html').removeClass('scrollUp');
        }
    }

    scrollPosCheck() {
        var scrollPos = window.pageYOffset || document.documentElement.scrollTop;

        if(scrollPos == 0){
            $('html').removeClass('pageScrolled');
        }else{
            $('html').addClass('pageScrolled');
        }
    }

}
