const Barba = require("barba.js");

export class BlogNavigation {
    constructor() {
        this.setupDone = false;

        this.blogNavOpen = false;
        this.blogNavOpening = false;
        this.blogNavClosing = false;
        this.currentTab = "search";
    }

    setup(){

        let scope = this;
        $('.js-blogNavExpand').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.toggleBlogNav();

            return false;
        });

        $('.js-blogSearch').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.showTab("search");

            return false;
        });
        $('.js-blogTags').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.showTab("tags");

            return false;
        });
        $('.js-blogSort').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.showTab("sort");

            return false;
        });
        $('.js-blogNavSignup').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            scope.showTab("signup");

            return false;
        });

        $('.js-blogSearchForm').on('submit', function(e) {
            e.preventDefault();
            window.location = $(this).data('action') +'/'+ $('input[name="search"]', this).val();
        });

        $('.js-blogLoadMore').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            let loadMore = $(this);
            let feature = loadMore.parents('.blogFeature');
            let spinner = $('.spinner', feature);

            loadMore.hide();
            spinner.show();

            let filters = feature.data('filters');
            let nextPage = feature.data('nextPage');
            let limit = feature.data('limit');
            let offset = feature.data('offset');
            if (nextPage === undefined) {
                nextPage = 2;
            }
            let postsContainer = $('.posts', feature);

            $.ajax({
                url: '/api/blog?'+ filters + '&page='+ nextPage + '&limit='+ limit + '&offset='+ offset,
                method: 'GET',
                dataType: 'json',
                success: function(data) {
                    postsContainer.append(data.markup);
                    feature.data('nextPage', nextPage + 1);
                },
                complete: function(data) {
                    spinner.hide();
                    if (data.responseJSON.nextPageAvailable) {
                        loadMore.show();
                    }
                }
            });

            return false;
        });

        /* Subfilters */
        $('li.has-subfilter > a').click(function(e){
            e.preventDefault();
            let ul = $(this).parents('ul');
            $('div.subfilter', ul).removeClass('active');
            $('div.subfilter', $(this).parent()).toggleClass('active');
        });

        $('li.has-subfilter').mouseleave(function(e){
            $('div.subfilter', this).removeClass('active');
        });

        /* Open search tab when no results found for current query */
        if ($('.js-blogNav-trigger-search-tab').length) {
            this.toggleBlogNav();
            this.showTab('search');
            this.getTab('search').addClass('no-results');
        }

        /* Close blogNav when ESC clicked */
        $(document).keyup(function(e) {
            if (e.keyCode === 27) {
                this.closeBlogNav();
            }
        }.bind(this));

        $('.blogNav-header').click(this.blogGoBack);
        $('.js-blogGoBack').click(this.blogGoBack);

        this.setupDone = true;
    }

    blogGoBack(e){
        //if current page is article
        console.log("btb");
        if ($('body').hasClass('page-ourBlog')){
            global.pageLoading.gotoPreviousURL(e);
        }
    }

    toggleBlogNav(){
        if(this.blogNavOpen){
            this.closeBlogNav();
        }else{
            this.openBlogNav();
        }
    }

    showCurrentTab(){
        this.showTab(this.currentTab);
    }

    getTab(tabName){
        return $(".blogNav .blogNav-tab-"+ tabName)
    }

    showTab(tabName){
        this.hideTabs();
        $('.blogNav-footer-icon-'+ tabName).addClass('active');

        this.getTab(tabName).show();
        this.currentTab = tabName;
        if(!this.blogNavOpen){
            this.openBlogNav();
        }
    }

    showSignupTab(){
        this.showTab("signup");
    }

    hideTabs(){
        $('.blogNav-footer-icon').removeClass('active');
        $(".blogNav .blogNav-tab").hide();
    }

    openBlogNav(fromBlogPage = false){
        if(!this.blogNavOpen && !this.blogNavOpening && !this.blogNavClosing){
            if(fromBlogPage){
                this.currentTab = "intro";
            }

            $('html').addClass('blogNavOpen');
            $('html').addClass('blogNavOpening');
            this.blogNavOpen = true;
            this.blogNavOpening = true;

            let scope = this;
            setTimeout(function(){
                scope.showCurrentTab();
                scope.blogNavOpening = false;
                $('html').removeClass('blogNavOpening');
            },800);
        }
    }

    closeBlogNav(){
        if(this.blogNavOpen && !this.blogNavOpening && !this.blogNavClosing){
            $('html').removeClass('blogNavOpen');
            this.hideTabs();

            this.blogNavOpen = false;
            this.blogNavClosing = true;
            $('html').addClass('blogNavClosing');
            $('html').removeClass('scrollDown scrollUp');

            let scope = this;
            setTimeout(function(){
                scope.blogNavClosing = false;
                $('html').removeClass('blogNavClosing');
            },500);
        }
    }

    destroy(){
        if (this.setupDone) {
            //destroy / cleanup

            this.blogNavOpen = false;
            this.blogNavOpening = false;
            this.blogNavClosing = false;
            this.currentTab = "search";
            $('html').removeClass('blogNavOpen');
            $('html').removeClass('blogNavOpening');
            $('html').removeClass('blogNavClosing');
        }
    }
}
