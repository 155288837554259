export class Linkedin {
    constructor() {
      this.trackingId = "Linkedin";
      this.trackingSetup = false;
      this.trackingScript = '_linkedin_partner_id = "599289";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);(function(){var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})();';
    }

    setup() {
      if(!this.trackingSetup){
        console.log(this.trackingId+' Setup');
        this.intialiseLinkedin();
      }
    }

    intialiseLinkedin(){
        // Initialise Linkedin Tracking if we should (according to cookies)
        if(global.cookieAllowStats){
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = this.trackingScript;
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            console.info(this.trackingId+' Initialised');
            this.trackingSetup = true;
        }else{
            console.info(this.trackingId+' Not Initialised');
        }
    }

}
