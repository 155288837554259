const fitvids = require("fitvids");

export class VideoOverlay {
    constructor() {
        this.setupDone = false;
        this.videoOverlayOpen = false;
        this.initialised = false;
    }

    setup(){
        if(!this.setupDone){
            console.log("setup video");
            let scope = this;
            $('.js-videoBlockLink').on('click', function(e) {
                e.stopPropagation();
                e.preventDefault();

                scope.setVideo($(this).data('video'));
                scope.openVideoOverlay();

                return false;
            });

            this.oneTimeSetup();

            this.setupDone = true;
        }
    }

    oneTimeSetup(){
        if(!this.initialised){

            let scope = this;
            $('.video-close').on('click', function(e) {
                e.stopPropagation();
                e.preventDefault();

                scope.closeVideoOverlay();

                return false;
            });

            $('.videoOverlay').on('click', function(e){
                //var senderElement = e.target;
                if(this === e.target){
                    e.stopPropagation();
                    e.preventDefault();

                    scope.closeVideoOverlay();

                    return false;
                }
            });

            $('.videoOverlay .video-holder').on('click', function(e){
                e.stopPropagation();
            });

            this.initialised = true;
        }
    }

    setVideo(videoData){
        if(videoData){
            $(".videoOverlay .fitvid").html(videoData);
        }else{
            console.error('(FROM VideoOverlay) No Video Data :: Error cause is:', error);
        }
    }

    toggleVideoOverlay(){
        if(this.videoOverlayOpen){
            this.closeVideoOverlay();
        }else{
            this.openVideoOverlay();
        }
    }

    openVideoOverlay(){
        if(!this.videoOverlayOpen){
            $('html').addClass('videoOverlayOpen');
            fitvids('.fitvid');
            this.videoOverlayOpen = true;
        }
    }

    closeVideoOverlay(){
        if(this.videoOverlayOpen){
            $('html').removeClass('videoOverlayOpen');
            $(".videoOverlay .fitvid").html(" ");
            this.videoOverlayOpen = false;
        }
    }

    destroy(){
        if (this.setupDone) {
            //destroy / cleanup

            this.setupDone = false;
            this.videoOverlayOpen = false;
            $('html').removeClass('videoOverlayOpen');
        }
    }
}
