global.comments = {
    findCommentContainer: function(anchor) {
        return anchor.closest('.cc-i-wrap');
    },
    findRepliesContainer: function(commentContainer) {
        return $('> [data-role="replies"]', commentContainer);
    },
    showReplies: function(anchor) {
        var commentContainer = this.findCommentContainer(anchor);
        var repliesContainer = this.findRepliesContainer(commentContainer);
        repliesContainer.show(400, function() {
            global.utils.scrollTo(repliesContainer, 254);
        });
        this.showCloseRepliesButton(commentContainer);
    },
    hideReplies: function(anchor) {
        var commentContainer = this.findCommentContainer(anchor);
        var repliesContainer = this.findRepliesContainer(commentContainer);
        repliesContainer.hide(400, function() {
            global.utils.scrollTo(commentContainer, 254);
        });
        this.showViewRepliesButton(commentContainer);
    },
    showViewRepliesButton: function(commentContainer) {
        $('> .cc-i-content [data-action="closeReplies"]', commentContainer).hide();
        $('> .cc-i-content [data-action="viewReplies"]', commentContainer).show();
    },
    showCloseRepliesButton: function(commentContainer) {
        $('> .cc-i-content [data-action="viewReplies"]', commentContainer).hide();
        $('> .cc-i-content [data-action="closeReplies"]', commentContainer).show();
    }
};

$(function(){
    $(document).on('click', '.cc-i [data-action="viewReplies"]', function(e){
        e.preventDefault();
        e.stopPropagation();

        global.comments.showReplies($(this));
        return false;
    });
    $(document).on('click', '.cc-i [data-action="closeReplies"]', function(e){
        e.preventDefault();
        e.stopPropagation();

        global.comments.hideReplies($(this));

        return false;
    });

    $(document).on('DOMNodeInserted DOMNodeRemoved', '.cc-i-wrap [data-role="replies"]', function(){
        //console.log(this);
        global.comments.showReplies($(this));
    });

    $(document).on('click', '.comments [data-action="viewAllComments"]', function(e){
        e.preventDefault();
        e.stopPropagation();

        $(this).closest('[data-role="comments"]').addClass('viewAllComments');

        return false;
    });

    $(document).on('click', '.comments [data-action="hideAllComments"]', function(e){
        e.preventDefault();
        e.stopPropagation();

        $(this).closest('[data-role="comments"]').removeClass('viewAllComments');

        return false;
    });
});
