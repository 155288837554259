export class GTag {
    constructor() {
      this.trackingId = "Google";
      this.trackingSetup = false;
      this.trackingScript = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MZNF7G7');";
      this.trackingScript2 = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-126561795-1');";
    }

    setup() {
      if(!this.trackingSetup){
        console.log(this.trackingId+' Setup');
        this.intialiseGoogle();
      }
    }

    intialiseGoogle(){
        // Initialise Google Tracking if we should (according to cookies)
        if(global.cookieAllowStats){
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = this.trackingScript;
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            var gtagScript = document.createElement("script");
            gtagScript.type = "text/javascript";
            gtagScript.setAttribute("async", "true");
            gtagScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=UA-126561795-1");
            document.head.appendChild(gtagScript);

            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = this.trackingScript2;
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            console.info(this.trackingId+' Initialised');
            this.trackingSetup = true;
        }else{
            console.info(this.trackingId+' Not Initialised');
        }
    }

}
