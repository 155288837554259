export class Sleeknote {
    constructor() {
      this.trackingId = "Sleeknote";
      this.trackingSetup = false;
      this.trackingScript = "(function(){var sleeknoteScriptTag = document.createElement('script');sleeknoteScriptTag.type = 'text/javascript';sleeknoteScriptTag.charset = 'utf-8';sleeknoteScriptTag.src = ('//sleeknotecustomerscripts.sleeknote.com/70.js');var s = document.getElementById('sleeknoteScript');s.parentNode.insertBefore(sleeknoteScriptTag, s);})();";
    }

    setup() {
      if(!this.trackingSetup){
        console.log(this.trackingId+' Setup');
        this.intialiseSleeknote();
      }
    }

    intialiseSleeknote(){
        // Initialise Sleeknote Tracking if we should (according to cookies)
        if(global.cookieAllowMarketing){
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = this.trackingScript;
            a.id = "sleeknoteScript";
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            console.info(this.trackingId+' Initialised');
            this.trackingSetup = true;
        }else{
            console.info(this.trackingId+' Not Initialised');
        }
    }

}
