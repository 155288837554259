export class Tracking {
    constructor() {
    }

    setup() {
      console.log("Setup Cookies");

      this.setupTracking();
    }

    setupTracking(){
      global.hotjar.setup();
      global.linkedin.setup();
      global.gtag.setup();
      global.sleeknote.setup();
    }

}
