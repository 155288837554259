// import jQBrowser from 'jquery.browser';
// https://github.com/webpack/webpack/issues/4039#issuecomment-273804003
const jQBrowser = require('jquery.browser');
require('../navigation/cookies.min.js');
const Velocity = require("velocity-animate");

export class DocumentReady {
    constructor() {}

    setupPageClasses() {
        $('html').removeClass('no-js');

        global.touch = false;
        if ("ontouchstart" in document.documentElement) {
            document.documentElement.className += ' touch';
            global.touch = true;
        } else {
            document.documentElement.className += ' no-touch';
        }

        $('html').addClass(jQBrowser.name);
        $('html').addClass(jQBrowser.platform);
        if(jQBrowser.msie){
            $('html').addClass('ie');
            $('html').addClass('ie-'+parseInt(jQBrowser.version));
            $('img').css("-ms-interpolation-mode", "bicubic");
        }else{
            $('html').addClass('no-ie');
        }

        $('html').addClass('page-initial-load');
    }

    setupNavigationLinks() {
        let scope = this;

        $('.logo').on('click', function(e) {
            if($('.logo .logo-blog').css('opacity') == 1){
                e.stopPropagation();
                e.preventDefault();

                return false;
            }
        });

        $('.logo .logo-bro').on('click', function(){
            window.location.href = $('.logo').attr('href');
        });
        $('.logo .logo-blog').on('click', function(){
            if($('.logo .logo-blog').css('opacity') == 1){
                window.location.href = $('.logo').attr('href')+'blog';
            }
        });

        //dont reload the same page
        $('nav a').on('click', function(e) {
            if ($(this).attr('href') === window.location.href && !$(this).hasClass('subMenuLink')) {
                e.stopPropagation();
                e.preventDefault();

                scope.closeMobileMenu();

                return false;
            }
        });

        $('.subMenuLink').on('click', function(e) {
            if(global.viewportWidth <= 914){
                e.stopPropagation();
                e.preventDefault();

                $('html').removeClass('mobileSubMenuOpen');
                if($(this).hasClass('mobileOpen')){
                    $('> li > .mobileOpen', $(this).closest('ul')).removeClass('mobileOpen');
                    //closing
                    //$(this).parent().find('.submenu').velocity('stop').velocity("slideUp", { duration: 300 });
                    var subM = $(this).parent().find('> .submenu')[0];
                    Velocity(subM, "stop");
                    Velocity(subM, "slideUp", 200);
                }else{
                    //var submenu = $(this).siblings('submenu');
                    //$('.mobileOpen').parent().find('.submenu').velocity('stop').velocity("slideUp", { duration: 300 });
                    var mobileOpen = $('> li > .mobileOpen', $(this).closest('ul'));
                    var openSM = mobileOpen.parent().find('.submenu').toArray();
                    Velocity(openSM, "stop");
                    Velocity(openSM, "slideUp", 200);
                    mobileOpen.removeClass('mobileOpen');
                    $(this).addClass('mobileOpen');
                    //opening
                    $('html').addClass('mobileSubMenuOpen');
                    //$(this).parent().find('.submenu').velocity('stop').velocity("slideDown", { duration: 500 });
                    var subM = $(this).parent().find('> .submenu')[0];
                    Velocity(subM, "stop");
                    Velocity(subM, "slideDown", 400);
                }

                return false;
            }
        });

        $('.submenu a:not(.subMenuLink)').on('click', function(e) {
            scope.closeMobileMenu();
        });

        //$('nav .menu-el').has('.submenu').hover(function(){
        $('.menu-el').hover(function(){
            $(this).addClass('hover');
            $('html').addClass('menuHovering');
            if($(this).hasClass('blogOverlayLink')){
                $('html').addClass('blogSearchHovering');
            }
        },function(){
            $(this).removeClass('hover');
            $('html').removeClass('menuHovering');
            $('html').removeClass('blogSearchHovering');
        });

        $('.no-touch .submenu-el').hover(function(){
            $(this).addClass('hover');
            $('html').addClass('submenuLinkHovering');
        },function(){
            $(this).removeClass('hover');
            $('html').removeClass('submenuLinkHovering');
        });



        $('.overlay-shareClose').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            $('html').toggleClass('socialOverlayShown');
            return false;
        });

        $('.js-blogNavShare').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            $('html').toggleClass('socialOverlayShown');
            return false;
        });

        $('.socialOverlay-close').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            $('html').removeClass('socialOverlayShown');
            return false;
        });

        $('.burgerstack').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            $('html').toggleClass('mobileMenuOpen');
            $('html').removeClass('scrollDown scrollUp');
            global.blogNavigation.closeBlogNav();

            return false;
        });

        //close the overlay (either go back, or goto page)
        $('.header-container .overlay-close').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();

            if($('html').hasClass('socialOverlayShown')){
                //close social overlay
                $('html').removeClass('socialOverlayShown');
            }else{
                global.pageLoading.gotoPreviousURL();
            }

            return false;
        });
    }

    closeMobileMenu(){
        $('html').removeClass('mobileSubMenuOpen');
        $('html').removeClass('mobileMenuOpen');
        $('.mobileOpen').parent().find('.submenu').stop().slideUp(300);
        $('.mobileOpen').removeClass('mobileOpen');
    }

    setupKeyboardEvents() {
        document.onkeydown = this.keypressHandler;
    }

    keypressHandler(e) {
        e = e || window.event;

        switch(e.keyCode){
            case '38':
                //up arrow
                break;
            case '40':
                //down arrow
                break;
            case 37:
                //left arrow
                if($('body').hasClass('fp-intro-slider')){
                    //slider active, trigger previous slide
                    global.introSlider.prevSlide();
                }
                break;
            case 39:
                //right arrow
                if($('body').hasClass('fp-intro-slider')){
                    //slider active, trigger next slide
                    global.introSlider.nextSlide();
                }
                break;
        }
    }
}
